@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "ComicNeue";
  src: local("ComicNeue"), url(./font/ComicNeue-Regular.ttf) format("truetype");
}
.bottom-negative-30 {
  bottom: -30px;

}
@media only screen and (max-width: 768px) {
  .bottom-negative-30 {
  bottom: -250px;
  margin-bottom: 16rem;
  }
}
.height-extra {
  height: 36rem;
}
@media only screen and (max-width: 1440px) {
  .height-extra {
    height: 25rem;
  }
}
.bg-paw {
  background-image: url("./img/bg-paw.png");
}

.border-red-dotted {
  border-top: 8px dotted red;
}

.border-bottom-red-dotted {
  border-bottom: 4px dotted red;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

#list {
  list-style-type: circle;
  margin-left: 1.5rem;
}
/* .margin-negative-left {
  text-indent: -140px;
} */
.list li {
  padding-bottom: 1rem;
}

.list {
  list-style-type: circle;
  margin-left: 1.5rem;
}

body {
  background-color: #FDF7F2;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-tan {
  background-color: #FDF7F2!important;
}

.bg-tan-200 {
  background-color: #e9e1db;
}

.coffee {
  color: #70655a;
}

.coffee-light {
  color: #83776c;
}

.bg-coffee {
  background-color: #70655a;
}

.bg-tan-100 {
  background-color: #FAEFE7;
}

.coffee-link:hover {
  color: #553f34;
}

.nekored {
  color: #ED3434;
}
.bg-discord-blue {
  background-color: #738ADB;
}

.bg-orange {
  background-color: rgb(251 146 60);
}

footer {
  background-color: #f7efe4!important;
}

.prototype {
  font-family: Prototype;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bright-teal {
  color: #f27602;
}

.white {
  color: #fff;
}

.teal {
  color: #f27602;
}

.bg-teal {
  background-color: #02D7F2;
}

.glow {
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

.pokemon {
  font-family: OldSchoolAdventures;
}

.sun {
  width: 80px;
  height: 80px;

  background-color: #fff;
  box-shadow:
    0 0 35px 5px #fff,  /* inner white */
    0 0 30px 8px #f0f, /* middle magenta */
    0 0 50px 16px #0ff; /* outer cyan */
}

.neon-text {
  color: #fff;
  text-shadow:
  0 0 40px #fff,  /* inner white */
  0 0 50px #f0f, /* middle magenta */
  0 0 100px  #0ff; /* outer cyan */
}

.neon-text-2 {
  color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}


.aug-box-1{
  --aug-tl-clip: 100px;
  --aug-border-bg: #90f2ff;
  --aug-border-all: 2px;
  --aug-border-opacity: 0.8;

  /* --aug-clip-t1: initial;
  --aug-clip-t2: initial; */
  /* --aug-clip-r1: initial;
  --aug-clip-r2: initial; */
  /* --aug-clip-l1: initial;
  --aug-clip-l2: initial; */
  /* --aug-clip-b1: initial;
  --aug-clip-b2: initial; */
  /* --aug-t-extend1: 50%; */
  /* --aug-r-extend1: 50%; */
  /* --aug-l-extend1: 50%; */
  /* --aug-b-extend1: 50%; */

  --aug-inlay-opacity: 1;
  --aug-inlay-all: 0px;
  --aug-inlay-bg: radial-gradient(ellipse at center, rgba(28, 0, 65, 0.6) 45px, rgba(1, 27, 66, 0.9) 100%);

  backdrop-filter: blur(50px);
}
.aug-box-prize-middle {
  /* --aug-clip-tr1: initial;
  --aug-tr-inset1: 40px;
  --aug-clip-br-y: initial; */

}
.aug-box-prize {

  --aug-border-bg: #90f2ff;
  --aug-border-all: 2px;
  --aug-border-opacity: 0.8;

  --aug-inlay-opacity: 1;
  --aug-inlay-all: 0px;
  --aug-inlay-bg: radial-gradient(ellipse at center, rgba(28, 0, 65, 0.2) 45px, rgba(1, 27, 66, 0.8) 100%);

  backdrop-filter: blur(24px);
}

.aug-button {
  --aug-border-all: 2px;
  --aug-inlay-opacity: 0;

}

.aug-box-large-prize {
  /* --aug-clip-l1: initial;
  --aug-l-extend1: 40%;
  --aug-clip-l2: initial; */

  --aug-border-bg: #90f2ff;
  --aug-border-all: 2px;
  --aug-border-opacity: 0.8;

  --aug-inlay-opacity: 1;
  --aug-inlay-all: 0px;
  --aug-inlay-bg: radial-gradient(ellipse at center, rgba(28, 0, 65, 0.2) 45px, rgba(1, 27, 66, 0.8) 100%);

  backdrop-filter: blur(24px);
}

.walletButtonConnected {
  background-color: rgba(255,255,255,.2) !important;
  color: #f27602 !important;
  border-radius: 20px !important;
}

.DialogWindow {
  width: 80%;
  transform: translate(10%, 0%);
  border-radius: 0px 15px 15px 15px;
  background-color: #0037a6;
  /* border-image: url("./img/borderbox_simple.png") 28 / 10px 10px 10px 10px stretch; */
  padding: 15px;
}

.DialogTitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.DialogFooter {
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 15px;
  text-align: end;
}

.DialogTypist {
  font-family: 'Press Start 2P', cursive;
}

.box{
  background: #111845a6;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgb(23, 32, 90);
  border: 2px solid #2a3cad;
  color: #f27602;
  padding: 20px;
  animation: breathe 2s linear infinite;
  border-style: double;
}

.box:before{
  width:100%;
  height:100%;
  background: rgba(255,255,255,0.1);
  transition:0.5s;
  pointer-events: none;
}

.box .content{
  border:1px solid #f0a591;
  padding:20px;
  text-align:center;
  box-shadow: 0 5px 10px rgba(9,0,0,0.5);
}

@keyframes breathe {
 0% {
   box-shadow: 0 20px 50px rgb(23, 32, 0);
   border: 2px solid rgba(42, 60, 173, .1);
   border-style: double;
 }
 50% {
   box-shadow: 0 30px 50px rgb(23, 32, 90);
   border: 2px solid rgba(42, 60, 173, .9);
   border-style: double;
 }
 100% {
   box-shadow: 0 20px 50px rgb(23, 32, 0);
   border: 2px solid rgba(42, 60, 173, .1);
   border-style: double;
 }
}

.footer {
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
}

.desktop {
  width: 150%;
  background-size: 100% 100%;
  background-size: cover;
  background-image: url("./img/desktop.png");
  z-index: 0;
}

.terminalContainer {
  display: flex;
  justify-content: center;
  width: 900px;
}

.imageControlled {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.backlitScreen {
  position: absolute;
  align-self: center;
  width: 815px;
  height: 545px;
  animation: glow 2s infinite alternate;
  border-radius: 25px;
  opacity: 20%;
}

.termMenuBar {
  position: absolute;
  align-self: center;
  transform: translate(330px, -260px);
  font-size: 12px;
}

@keyframes glow {
  from {
    opacity: 20%;
    /* box-shadow: 0px 0px 120px white, 0 0 200px 120px white inset; */
    box-shadow: inset 0 -60px 80px #be96de, inset 0 60px 120px #6685cb;
  }
  to {
    opacity: 60%;
    /* box-shadow: 0px 0px 120px white, 0 0 200px 180px white inset; */
    box-shadow: inset 0 -60px 80px #be96de, inset 0 60px 120px #6685cb;
  }
}

.terminalDialogWindow {
  transform: translate(0px, -100px);
  width: 550px;
  position: absolute;
  border-radius: 0px 15px 15px 15px;
  background-color: #0037a6;
  padding: 15px;
  align-self: center;
}

.terminalDialogTitle {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.terminalDialogFooter {
  cursor: pointer;
  font-size: 0.7rem;
  margin-top: 15px;
  text-align: end;
}

.terminalDialogTypist {
  font-family: 'Press Start 2P', cursive;
  font-size: 8px;
}

.terminalBox{
  background: #111845a6;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgb(23, 32, 90);
  border: 2px solid #2a3cad;
  color: white;
  padding: 20px;
  animation: breathe 2s linear infinite;
  border-style: double;
}

.terminalBox:before{
  width:100%;
  height:100%;
  background: rgba(255,255,255,0.1);
  transition:0.5s;
  pointer-events: none;
}

.terminalBox .content{
  border:1px solid #f0a591;
  padding:20px;
  text-align:center;
  box-shadow: 0 5px 10px rgba(9,0,0,0.5);
}

.gray-common {
  color: #E5E7EB;
}

.green-uncommon {
  color: #02d425;
}

.blue-rare {
  color: #406eee;
}

.purple-ultra {
  color: #ac17f1;
}

.yellow-sssuper {
  color: #ff7113;
}

.mintBtn {
  position:relative;
  top: 0;
  transition: background-color .2s, top ease .2s;
}

.mintBtn:hover {
  background-color: #fa801c;
  top: -2px;
}

.mintBtn:active {
  background-color: #fcb276;
  transform: translateY(1px);
}

.threeIconsParent:hover > div > div > div > .threeIcons {
  -webkit-animation-name: floater;
  animation-name: floater;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 1.9s;
  animation-duration: 1.9s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  transform: translateY(2%);
}

@keyframes floater {
  0% { transform: translateY(2%); animation-timing-function: cubic-bezier(0.33333, 0.66667, 0.66667, 1) }
  69.0983% { transform: translateY(-2%); animation-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333) }
  100% { transform: translateY(2%) }
}
