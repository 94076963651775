$bg-color: rgba(12, 18, 50, 255);
$glitch-bg-color: $bg-color;

.glitch {
  position: relative;
  margin: 0 auto;
}

// kongzGlitch
.kongzGlitch::before,
.kongzGlitch::after {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900px, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.kongzGlitch::after {
  font-size: 1.125em;
  font-weight: bold;
  animation-name: glitch-animation;
  animation-duration: 6s;
  left: 100px;
  text-shadow: -1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

.kongzGlitch::before {
  font-size: 1.125em;
  animation-name: glitch-animation-2;
  animation-duration: 8s;
  left: 80px;
  text-shadow: 1px 0 #4dacf5;
  background: $glitch-bg-color;
}

// toadzGlitch
.toadzGlitch::before,
.toadzGlitch::after {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900px, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.toadzGlitch::after {
  animation-name: glitch-animation;
  animation-duration: 10s;
  left: 24px;
  text-shadow: -1px 0 #4dacf5;
  background: $glitch-bg-color;
}

.toadzGlitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 3s;
  left: 17px;
  text-shadow: 1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

// ccGlitch
.ccGlitch::before,
.ccGlitch::after {
  animation-iteration-count: 45;
  animation-timing-function: ease-in;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900px, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.ccGlitch::after {
  animation-name: glitch-animation;
  animation-duration: 4s;
  right: 4px;
  top: -2px;
  text-shadow: -1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

.ccGlitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 6s;
  right: -4px;
  top: -3px;
  text-shadow: 1px 0 #4dacf5;
  background: $glitch-bg-color;
}

// ducksGlitch
.ducksGlitch::before,
.ducksGlitch::after {
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.2,-2,0.8,2);;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 1300px, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.ducksGlitch::after {
  animation-name: glitch-animation;
  animation-duration: 14s;
  right: -10px;
  top: -3px;
  text-shadow: -1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

.ducksGlitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 18s;
  right: -15px;
  top: -3px;
  text-shadow: 1px 0 #4dacf5;
  background: $glitch-bg-color;
}

// penguGlitch
.penguGlitch::before,
.penguGlitch::after {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900px, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.penguGlitch::after {
  animation-name: glitch-animation;
  animation-duration: 4s;
  left: 4px;
  text-shadow: -1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

.penguGlitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 6s;
  left: -4px;
  text-shadow: 1px 0 #4dacf5;
  background: $glitch-bg-color;
}

// kongzvxGlitch
.kongzvxGlitch::before,
.kongzvxGlitch::after {
  animation-iteration-count: 15;
  animation-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 1100px, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.kongzvxGlitch::after {
  animation-name: glitch-animation;
  animation-duration: 8s;
  right: 53px;
  top: -10px;
  text-shadow: -1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

.kongzvxGlitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 10s;
  right: 65px;
  top: -10px;
  text-shadow: 1px 0 #4dacf5;
  background: $glitch-bg-color;
}

// creaturesGlitch
.creaturesGlitch::before,
.creaturesGlitch::after {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900, 0, 0);
  content: attr(data-text);
  background: $glitch-bg-color;
}

.creaturesGlitch::after {
  animation-name: glitch-animation;
  animation-duration: 10s;
  left: 56px;
  text-shadow: -1px 0 #ce1c8d;
  background: $glitch-bg-color;
}

.creaturesGlitch::before {
  animation-name: glitch-animation-2;
  animation-duration: 6s;
  left: 32px;
  text-shadow: 1px 0 #4dacf5;
  background: $glitch-bg-color;
}

@mixin rect($top, $bottom) {
  clip: rect($top, 9999px, $bottom, 0);
}

/* Expanded Animations */
@keyframes glitch-animation {
  0% { @include rect(42px, 44px); }
  5% { @include rect(12px, 59px); }
  10% { @include rect(48px, 29px); }
  15.0% { @include rect(42px, 73px); }
  20% { @include rect(63px, 27px); }
  25% { @include rect(34px, 55px); }
  30.0% { @include rect(86px, 73px); }
  35% { @include rect(20px, 20px); }
  40% { @include rect(26px, 60px); }
  45% { @include rect(25px, 66px); }
  50% { @include rect(57px, 98px); }
  55.0% { @include rect(5px, 46px); }
  60.0% { @include rect(82px, 31px); }
  65% { @include rect(54px, 27px); }
  70% { @include rect(28px, 99px); }
  75% { @include rect(45px, 69px); }
  80% { @include rect(23px, 85px); }
  85.0% { @include rect(54px, 84px); }
  90% { @include rect(45px, 47px); }
  95% { @include rect(37px, 20px); }
  100% { @include rect(4px, 91px); }
}

@keyframes glitch-animation-2 {
  0% { @include rect(65px, 100px); }
  5% { @include rect(52px, 74px); }
  10% { @include rect(79px, 85px); }
  15.0% { @include rect(75px, 5px); }
  20% { @include rect(67px, 61px); }
  25% { @include rect(14px, 79px); }
  30.0% { @include rect(1px, 66px); }
  35% { @include rect(86px, 30px); }
  40% { @include rect(23px, 98px); }
  45% { @include rect(85px, 72px); }
  50% { @include rect(71px, 75px); }
  55.0% { @include rect(2px, 48px); }
  60.0% { @include rect(30px, 16px); }
  65% { @include rect(59px, 50px); }
  70% { @include rect(41px, 62px); }
  75% { @include rect(2px, 82px); }
  80% { @include rect(47px, 73px); }
  85.0% { @include rect(3px, 27px); }
  90% { @include rect(26px, 55px); }
  95% { @include rect(42px, 97px); }
  100% { @include rect(38px, 49px); }
}

.lmao {
  width: 68px;
  border-radius: 100%;
  position: absolute;
  transform: translate(-300px, 100px);
  background: linear-gradient(to right, red, purple);
  padding: 2px;
}

@keyframes rotateBorder {
  0% {
    background: linear-gradient(0deg, #e13a7b, #6cf9e1);
  }
  10% {
    background: linear-gradient(36deg, #e13a7b, #6cf9e1);
  }
  20% {
    background: linear-gradient(72deg, #e13a7b, #6cf9e1);
  }
  30% {
    background: linear-gradient(108deg, #e13a7b, #6cf9e1);
  }
  40% {
    background: linear-gradient(144deg, #e13a7b, #6cf9e1);
  }
  50% {
    background: linear-gradient(180deg, #e13a7b, #6cf9e1);
  }
  60% {
    background: linear-gradient(216deg, #e13a7b, #6cf9e1);
  }
  70% {
    background: linear-gradient(252deg, #e13a7b, #6cf9e1);
  }
  80% {
    background: linear-gradient(288deg, #e13a7b, #6cf9e1);
  }
  90% {
    background: linear-gradient(324deg, #e13a7b, #6cf9e1);
  }
  100% {
    background: linear-gradient(360deg, #e13a7b, #6cf9e1);
  }
}

.animateRotateBorder {
  animation: rotateBorder .8s cubic-bezier(0.0, 0.0, .99, 1.0) infinite;
}

@keyframes disappearText {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes minimize {
  from { z-index: 1;}
  65%{
    transform: translate(-240px, -150px);;
  }
  98% {
    color: rgba(0, 0, 0, 0);
    transform: translate(-240px, -150px);;
    opacity: 0%;
    font-size: 5%;
  }
  to {
    transform: scale(0) translate(-240px, -150px);
    color: rgba(0, 0, 0, 0);
    width: 0px;
    height: 0px;
    opacity: 0;
    font-size: 1%;
  }
}
@-moz-keyframes minimize {
  from { z-index: 1;}
  65%{
    transform: translate(-240px, -150px);;
  }
  98% {
    color: rgba(0, 0, 0, 0);
    transform: translate(-240px, -150px);;
    opacity: 0%;
    font-size: 5%;
  }
  to {
    transform: scale(0) translate(-240px, -150px);
    color: rgba(0, 0, 0, 0);
    width: 0px;
    height: 0px;
    opacity: 0;
    font-size: 1%;
  }
}
@-webkit-keyframes minimize {
  from { z-index: 1;}
  65%{
    transform: translate(-240px, -150px);;
  }
  98% {
    color: rgba(0, 0, 0, 0);
    transform: translate(-240px, -150px);;
    opacity: 0%;
    font-size: 5%;
  }
  to {
    transform: scale(0) translate(-240px, -150px);
    color: rgba(0, 0, 0, 0);
    width: 0px;
    height: 0px;
    opacity: 0;
    font-size: 1%;
  }
}
@-o-keyframes minimize {
  from { z-index: 1;}
  65%{
    transform: translate(-240px, -150px);;
  }
  98% {
    color: rgba(0, 0, 0, 0);
    transform: translate(-240px, -150px);;
    opacity: 0%;
    font-size: 5%;
  }
  to {
    transform: scale(0) translate(-240px, -150px);
    color: rgba(0, 0, 0, 0);
    width: 0px;
    height: 0px;
    opacity: 0;
    font-size: 1%;
  }
}
@-ms-keyframes minimize {
  from { z-index: 1;}
  65%{
    transform: translate(-240px, -150px);;
  }
  98% {
    color: rgba(0, 0, 0, 0);
    transform: translate(-240px, -150px);;
    opacity: 0%;
    font-size: 5%;
  }
  to {
    transform: scale(0) translate(-240px, -150px);
    color: rgba(0, 0, 0, 0);
    width: 0px;
    height: 0px;
    opacity: 0;
    font-size: 1%;
  }
}

@keyframes minimizeLmao {
  from { z-index: 1000; }
  98% {
    display: inline-block;
    width: 3px;
    height: 3px;
    opacity: 0%;
    font-size: 0px;
  }
  to {
    display: inline-block;
    translate: scale(0);
    opacity: 0;
    font-size: .1px;
  }
}
@-moz-keyframes minimizeLmao {
  from { z-index: 1000; }
  98% {
    display: inline-block;
    width: 3px;
    height: 3px;
    opacity: 0%;
    font-size: 0px;
  }
  to {
    display: inline-block;
    translate: scale(0);
    opacity: 0;
    font-size: .1px;
  }
}
@-webkit-keyframes minimizeLmao {
  from { z-index: 1000; }
  98% {
    display: inline-block;
    width: 3px;
    height: 3px;
    opacity: 0%;
    font-size: 0px;
  }
  to {
    display: inline-block;
    translate: scale(0);
    opacity: 0;
    font-size: .1px;
  }
}
@-o-keyframes minimizeLmao {
  from { z-index: 1000; }
  98% {
    display: inline-block;
    width: 3px;
    height: 3px;
    opacity: 0%;
    font-size: 0px;
  }
  to {
    display: inline-block;
    translate: scale(0);
    opacity: 0;
    font-size: .1px;
  }
}
@-ms-keyframes minimizeLmao {
  from { z-index: 1000; }
  98% {
    display: inline-block;
    width: 3px;
    height: 3px;
    opacity: 0%;
    font-size: 0px;
  }
  to {
    display: inline-block;
    translate: scale(0);
    opacity: 0;
    font-size: .1px;
  }
}

.disappearText {
  animation: disappearText 0.2s normal forwards ease-in;
}

.minimizedLmao {
  z-index: 1000;
  animation: minimizeLmao 0.8s normal forwards ease-in;
  -webkit-animation: minimizeLmao 0.8s normal forwards ease-in;
  -moz-animation: minimizeLmao 0.8s normal forwards ease-in;
  -o-animation: minimizeLmao 0.8s normal forwards ease-in;
  -ms-animation: minimizeLmao 0.8s normal forwards ease-in;
  animation-delay: 0.6s;
}

.minimized {
  animation: minimize 0.7s normal forwards ease-in;
  -webkit-animation: minimize 0.7s normal forwards ease-in;
  -moz-animation: minimize 0.7s normal forwards ease-in;
  -o-animation: minimize 0.7s normal forwards ease-in;
  -ms-animation: minimize 0.7s normal forwards ease-in;
  animation-delay: 0.2s;
}

@keyframes cliActive {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    width: 700px;
    opacity: 1;
    transform: scale(1);
  }
}

.cli {
  text-align: left;
  padding: 0px 0px;
  height: 0px;
}

.cli div div span {
  padding: 0px 0px !important;
}

.cliActive {
  text-align: left;
  opacity: 0;
  width: 700px;
  position: absolute;
  align-self: center;
  animation: cliActive .5s normal forwards ease-in;
  animation-delay: 1s;
}
